import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import { colors, mq } from "../components/styles"

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showMenu: false }
  }

  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu,
    })
  }

  render() {
    const { siteTitle, logo, globalYaml } = this.props
    const navLinks = globalYaml.navbar
    return (
      <>
        <div
          classname="is-hidden-touch has-text-centered"
          style={{ background: "#0a0a0a" }}
        >

          <iframe
            title="iframe amzn on desktop 2"
            className="is-hidden-touch"
            src={`https://rcm-eu.amazon-adsystem.com/e/cm?o=8&p=48&l=ur1&category=prime_student&banner=08Y1G45JJB18HNPAKP82&f=ifr&linkID=d89fa6acf5a1d49ee5f52e1e66da16e3&t=francemixtape-21&tracking_id=francemixtape-21`}
            width="728"
            height="90"
            scrolling="no"
            border="0"
            marginWidth="0"
            style={{ border: "none", margin: "auto", display: "flex" }}
            frameBorder="0"
          />
        </div>
        <nav
          className="navbar is-black"
          role="navigation"
          aria-label="main navigation"
          css={navbarCss}
        >
          <div className="navbar-brand">
            <Link className="navbar-item" to={"/"}>
              <Img fixed={logo.childImageSharp.fixed} alt={siteTitle} />
            </Link>

            <button
              onClick={this.toggleMenu}
              className={`navbar-burger burger ${this.state.showMenu &&
                "is-active"}`}
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample"
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </button>
          </div>
          <div
            id="navbarBasicExample"
            className={`navbar-menu ${this.state.showMenu && "is-active"}`}
          >
            <div className="navbar-start">
              {navLinks.map((navLink, i) => {
                return (
                  <Link
                    key={i}
                    to={navLink.slug}
                    className="navbar-item"
                    onClick={this.toggleMenu}
                  >
                    {navLink.name}
                  </Link>
                )
              })}
              <Link to={"/mixtapes"} className="navbar-item itemBold">
                <span> Mixtapes</span>
              </Link>
            </div>
          </div>
        </nav>
      </>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

const navbarCss = css`
  .navbar-burger {
    background: none;
    border: none;
  }
  .itemBold {
    span {
      background: lightgrey;
      padding: 0 5px;
      border-radius: 3px;
      font-size: 0.8rem;
      ${mq[1]} {
        background: ${colors.primary};
        &:hover {
          background: ${colors.secondary};
        }
      }
    }
  }
`
