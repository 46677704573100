import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { css } from "@emotion/core"
import { colors } from "../components/styles"

import Header from "./header"
import Footer from "./footer"

// import "../styles/hc.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site: site {
        siteMetadata {
          title
        }
      }
      logo: file(relativePath: { eq: "hauteculture.png" }) {
        childImageSharp {
          fixed(width: 130) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      globalYaml: globalYaml {
        navbar {
          name
          slug
        }
      }
    }
  `)
  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata.title}
        logo={data.logo}
        globalYaml={data.globalYaml}
      />
      <main css={layoutCss}>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout


const layoutCss = css`
  a {
    color: ${colors.primary};
  }
`
