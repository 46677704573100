import { keyframes } from "@emotion/core"
// Haute styles

export const colors = {
  primary: "#fe1371",
  primary_rgb: "254, 19, 113",
  secondary: "#19f77d",
  white: "#ffffff",

  text: "#4A4A4A",
  
  amazon_orange: "#FF9900",
  amazon_black: "#232F3E",
  amazon_red: "#B12704",

}

// Media Queries
const breakpoints = [769, 1024, 1216, 1408]
export const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`)

export const emojiBounce = keyframes`
   0% {
    transform: scale(1.2);
  }

  80% {
    transform: scale(1);
  }

  90% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`
