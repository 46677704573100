import React from "react"
import { css } from "@emotion/core"

const Footer = () => {
  return (
    <footer className="footer" css={cssFooter}>
      <div className="content has-text-centered">
        <span>
          <strong>HauteCulture</strong> {new Date().getFullYear()}
        </span>
        <span>
          <a href="/assets/cgu.pdf" target="_blank" rel="noopener noreferrer">
            CGU
          </a>
        </span>

        <span>
          <a
            href="https://www.facebook.com/hauteculture/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook
          </a>
        </span>
      </div>
    </footer>
  )
}

export default Footer

const cssFooter = css`
  &.footer {
    .content {
      span {
        margin: 0 10px;
        a {
          color: inherit;
        }
      }
    }
  }
`
